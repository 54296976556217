export const Contracts = (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="216"
    height="42"
    viewBox="0 0 216 42"
    fill="none"
  >
    <g clipPath="url(#clip0_254_3356)">
      <path
        d="M20.9953 0C9.40453 0 0 9.40453 0 20.9953C0 32.586 9.40453 41.9906 20.9953 41.9906C32.586 41.9906 41.9906 32.586 41.9906 20.9953C41.9906 9.40453 32.5955 0 20.9953 0Z"
        fill="#AECFFF"
      />
      <path
        d="M48.3985 11.0254C48.4079 11.93 48.7472 12.6745 49.4068 13.2304C50.0853 13.777 51.0182 14.0597 52.215 14.0597C53.4117 14.0597 54.2504 13.8053 54.9195 13.287C55.598 12.7781 55.9278 12.0619 55.9278 11.1479C55.9278 9.64956 54.9195 8.80145 53.4212 8.50933L51.8192 8.18893C51.4705 8.12297 51.4328 8.0947 51.0936 7.98162C50.924 7.91566 50.8015 7.84027 50.7072 7.7743C50.5565 7.62353 50.3586 7.34083 50.3586 6.99216C50.3586 6.23829 50.9522 5.78597 52.0077 5.78597C53.0631 5.78597 53.7981 6.27599 53.8641 7.2089H55.5885C55.5791 6.37964 55.2587 5.71058 54.6274 5.21115C53.996 4.71171 53.1573 4.45728 52.083 4.45728C51.0088 4.45728 50.1889 4.71171 49.5482 5.22999C48.9074 5.72943 48.587 6.39849 48.587 7.22775C48.587 7.98162 48.9168 8.59414 49.2654 8.91453C49.4445 9.08415 49.6707 9.2255 49.9722 9.36686C50.2643 9.49878 50.4999 9.58359 50.6507 9.62129C50.8015 9.65898 51.0088 9.71552 51.2632 9.76264L52.8369 10.0925C53.1479 10.1584 53.2987 10.1961 53.6191 10.3846C53.9677 10.5542 54.1279 10.8746 54.1279 11.3175C54.1279 12.1468 53.4212 12.7027 52.2338 12.7027C50.9051 12.7027 50.1701 12.0996 50.123 11.0159H48.3985V11.0254Z"
        fill="#1A1C1E"
      />
      <path
        d="M64.0884 13.9372V12.4672H59.3296V9.80034H63.6455V8.4151H59.3296V6.06868H64.0884V4.59863H57.6617V13.9372H64.0884Z"
        fill="#1A1C1E"
      />
      <path
        d="M67.5562 13.9373V10.5166H70.0157C71.024 10.5166 71.3633 10.9218 71.3633 11.8359V12.8065C71.3633 13.3531 71.4104 13.7394 71.5046 13.9373H73.2197V13.8713C73.1066 13.7583 73.0783 13.3342 73.0783 12.863V11.5532C73.0783 10.5543 72.6543 9.72505 71.3538 9.72505V9.65909C72.6543 9.53658 73.2291 8.39635 73.2291 7.33151C73.2291 5.64473 72.1925 4.60815 70.289 4.60815H65.8977V13.9373H67.5656H67.5562ZM67.5562 6.05935H69.8367C70.9109 6.05935 71.4481 6.54937 71.4481 7.51998C71.4481 8.49059 70.7979 9.05599 69.7048 9.05599H67.5562V6.05935Z"
        fill="#1A1C1E"
      />
      <path
        d="M76.8288 4.59863H75.1609V13.9372H76.8288V4.59863Z"
        fill="#1A1C1E"
      />
      <path
        d="M85.5077 13.9372V12.4672H80.7489V9.80034H85.0648V8.4151H80.7489V6.06868H85.5077V4.59863H79.081V13.9372H85.5077Z"
        fill="#1A1C1E"
      />
      <path
        d="M86.5726 11.0254C86.5821 11.93 86.9213 12.6745 87.5809 13.2304C88.2594 13.777 89.1923 14.0597 90.3891 14.0597C91.5859 14.0597 92.4245 13.8053 93.0936 13.287C93.7721 12.7781 94.1019 12.0619 94.1019 11.1479C94.1019 9.64956 93.0936 8.80145 91.5953 8.50933L89.9933 8.18893C89.6447 8.12297 89.607 8.0947 89.2677 7.98162C89.0981 7.91566 88.9756 7.84027 88.8814 7.7743C88.7306 7.62353 88.5327 7.34083 88.5327 6.99216C88.5327 6.23829 89.1169 5.78597 90.1818 5.78597C91.2466 5.78597 91.9722 6.27599 92.0382 7.2089H93.7627C93.7532 6.37964 93.4329 5.71058 92.8015 5.21115C92.1701 4.71171 91.3314 4.45728 90.2572 4.45728C89.1829 4.45728 88.3631 4.71171 87.7223 5.22999C87.0815 5.72943 86.7611 6.39849 86.7611 7.22775C86.7611 7.98162 87.0909 8.59414 87.4396 8.91453C87.6186 9.08415 87.8448 9.2255 88.1463 9.36686C88.4385 9.49878 88.674 9.58359 88.8248 9.62129C88.9756 9.65898 89.1829 9.71552 89.4373 9.76264L91.011 10.0925C91.322 10.1584 91.4728 10.1961 91.7932 10.3846C92.1418 10.5542 92.302 10.8746 92.302 11.3175C92.302 12.1468 91.5953 12.7027 90.4079 12.7027C89.0793 12.7027 88.3442 12.0996 88.2971 11.0159H86.5726V11.0254Z"
        fill="#1A1C1E"
      />
      <path
        d="M48.3985 28.0062C48.3985 21.9847 53.063 18.5923 57.7841 18.5923C62.5053 18.5923 66.0013 21.3062 66.4913 25.4525H61.3556C60.9598 23.8882 59.6311 22.8988 57.7559 22.8988C55.4566 22.8988 53.6379 24.8306 53.6379 28.0062C53.6379 31.1819 55.4848 33.2174 57.7841 33.2174C59.584 33.2174 60.8844 32.275 61.3839 30.6354H66.5196C65.9448 34.7534 62.3168 37.5427 57.7276 37.5427C52.8557 37.5427 48.389 34.0749 48.389 27.9968L48.3985 28.0062Z"
        fill="#1A1C1E"
      />
      <path
        d="M67.9048 28.0062C67.9048 21.9564 72.626 18.5923 77.5544 18.5923C82.4828 18.5923 87.2039 21.8999 87.2039 28.0062C87.2039 34.1126 82.5582 37.5521 77.5261 37.5521C72.494 37.5521 67.9048 34.1126 67.9048 28.0062ZM81.9363 28.0062C81.9363 24.8494 80.061 22.8988 77.5544 22.8988C75.0478 22.8988 73.1442 24.8306 73.1442 28.0062C73.1442 31.1819 75.0478 33.2174 77.5544 33.2174C80.061 33.2174 81.9363 31.2573 81.9363 28.0062Z"
        fill="#1A1C1E"
      />
      <path
        d="M94.394 25.8953V37.2128H89.5221V18.9597H95.3929L101.942 30.2772V18.9597H106.814V37.2128H100.943L94.394 25.8953Z"
        fill="#1A1C1E"
      />
      <path
        d="M114.24 23.2097H108.944V18.9597H124.69V23.2097H119.366V37.2128H114.23V23.2097H114.24Z"
        fill="#1A1C1E"
      />
      <path
        d="M131.842 31.1065V37.2128H126.839V18.9597H135.602C140.531 18.9597 142.51 21.8056 142.51 24.9341C142.51 27.5633 141.049 29.3914 139.07 30.2301V30.2866C139.278 30.5976 139.381 30.8049 139.692 31.3515L143.056 37.2222H137.242L134.142 31.4363C134.01 31.1724 133.934 31.1253 133.595 31.1253H131.852L131.842 31.1065ZM131.842 27.1392H134.924C136.545 27.1392 137.374 26.3005 137.374 25.0566C137.374 23.8128 136.516 22.9175 134.924 22.9175H131.842V27.1392Z"
        fill="#1A1C1E"
      />
      <path
        d="M157.107 33.745H150.199L149.05 37.2128H143.857L150.614 18.9597H156.899L163.656 37.2128H158.256L157.107 33.745ZM155.778 29.806L153.639 23.417L151.528 29.806H155.778Z"
        fill="#1A1C1E"
      />
      <path
        d="M163.675 28.0062C163.675 21.9847 168.339 18.5923 173.06 18.5923C177.781 18.5923 181.278 21.3062 181.768 25.4525H176.632C176.236 23.8882 174.907 22.8988 173.032 22.8988C170.733 22.8988 168.914 24.8306 168.914 28.0062C168.914 31.1819 170.761 33.2174 173.06 33.2174C174.86 33.2174 176.161 32.275 176.66 30.6354H181.796C181.221 34.7534 177.593 37.5427 173.004 37.5427C168.132 37.5427 163.665 34.0749 163.665 27.9968L163.675 28.0062Z"
        fill="#1A1C1E"
      />
      <path
        d="M188.006 23.2097H182.71V18.9597H198.456V23.2097H193.132V37.2128H187.996V23.2097H188.006Z"
        fill="#1A1C1E"
      />
      <path
        d="M199.267 31.3987H204.299C204.506 33.0949 205.788 33.8487 207.503 33.8487C209.039 33.8487 210.132 33.2456 210.132 32.1243C210.132 31.1631 209.274 30.6636 207.503 30.2961L205.703 29.9286C201.528 29.0899 199.625 27.2429 199.625 24.3782C199.625 20.9387 202.961 18.5923 207.06 18.5923C211.159 18.5923 214.41 20.656 214.806 24.3782H209.821C209.614 22.993 208.539 22.2674 207.003 22.2674C205.646 22.2674 204.6 22.9459 204.6 23.9353C204.6 24.8211 205.279 25.1887 206.9 25.4996L208.86 25.886C212.742 26.6399 215.173 28.3078 215.173 31.6531C215.173 35.4036 211.602 37.5427 207.456 37.5427C203.074 37.5427 199.663 35.5826 199.267 31.3892V31.3987Z"
        fill="#1A1C1E"
      />
      <path
        d="M17.16 14.6062V17.16H14.6062V14.6062H17.16ZM19.7137 12.0525H12.0525V19.7231H19.7231V12.0525H19.7137Z"
        fill="#2E3356"
      />
      <path
        d="M27.3844 14.6062V17.16H24.8306V14.6062H27.3844ZM29.9381 12.0525H22.2675V19.7231H29.9381V12.0525Z"
        fill="#2E3356"
      />
      <path
        d="M17.16 24.8306V27.3843H14.6062V24.8306H17.16ZM19.7137 22.2769H12.0525V29.9475H19.7231V22.2769H19.7137Z"
        fill="#2E3356"
      />
      <path
        d="M27.3844 24.8306V27.3843H24.8306V24.8306H27.3844ZM29.9381 22.2769H22.2675V29.9475H29.9381V22.2769Z"
        fill="#2E3356"
      />
    </g>
    <defs>
      <clipPath id="clip0_254_3356">
        <rect width="215.173" height="42" fill="white" />
      </clipPath>
    </defs>
  </svg>
);
