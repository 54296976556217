import { Banking } from './icons/Banking';
import { Billing } from './icons/Billing';
import { Contracts } from './icons/Contracts';
import { Intel } from './icons/Intel';
import { Treasury } from './icons/Treasury';
import { Vault } from './icons/Vault';
import { WorkForce } from './icons/WorkForce';

export const isDev = process.env.NEXT_PUBLIC_SENTRY_ENV !== 'production';

export const APPS_CONFIG = {
  banking: {
    name: 'Cash',
    subscriptionKey: 'seriesBankingActivated',
    subscribed: false,
    icon: Banking,
    marketingSite: 'https://www.seriesfi.com/products/banking',
    productSite: isDev
      ? 'https://banking.series.engineering'
      : 'https://banking.seriesfi.com',
    description:
      'Series Cash is the operating system for banking and payments management.',
    testId: 'launch-banking-button',
  },
  billing: {
    icon: Billing,
    subscriptionKey: 'seriesBillingActivated',
    name: 'Billing',
    subscribed: false,
    marketingSite: 'https://www.seriesfi.com/products/billing',
    productSite: isDev
      ? 'https://billing.series.engineering'
      : 'https://billing.seriesfi.com',
    description:
      'Series Billing offers friction-free accounts payable, simplifying the payables process from receipt to settlement.',
    testId: 'launch-billing-button',
  },
  workForce: {
    name: 'Workforce',
    subscriptionKey: 'seriesWorkforceActivated',
    icon: WorkForce,
    subscribed: false,
    marketingSite: 'https://www.seriesfi.com/products/workforce',
    productSite: isDev
      ? 'https://workforce.series.engineering'
      : 'https://workforce.seriesfi.com',
    description:
      'Experience seamless payroll and HR management designed for growing teams with our comprehensive, user-friendly platform.',
    testId: 'launch-workForce-button',
  },
  vault: {
    name: 'Vault',
    subscriptionKey: 'seriesVaultActivated',
    icon: Vault,
    subscribed: false,
    marketingSite: 'https://www.seriesfi.com/products/vault',
    productSite: isDev
      ? 'https://vault.series.engineering'
      : 'https://vault.seriesfi.com',
    description:
      'Securely host and share your legal / entity / financial data and documents in one place.',
    testId: 'launch-vault-button',
  },
  invest: {
    name: 'Invest',
    subscriptionKey: 'seriesTreasuryActivated',
    icon: Treasury,
    subscribed: false,
    marketingSite: 'https://www.seriesfi.com/products/invest',
    productSite: isDev
      ? 'https://invest.series.engineering'
      : 'https://invest.seriesfi.com',
    description:
      'Access treasury management services for US treasury bills, money market funds, corporate bonds, and more.',
    testId: 'launch-treasury-button',
  },
  contracts: {
    name: 'Contracts',
    subscriptionKey: 'seriesContractActivated',
    icon: Contracts,
    subscribed: false,
    marketingSite: 'https://www.seriesfi.com/products/contracts',
    productSite: isDev
      ? 'https://contracts.series.engineering'
      : 'https://contracts.seriesfi.com',
    description:
      'Series Contracts is an intelligent source of truth for a business’s contracts and important legal documents.',
    testId: 'launch-contracts-button',
  },
  intel: {
    name: 'Intel',
    subscriptionKey: 'seriesIntelActivated',
    icon: Intel,
    subscribed: false,
    marketingSite: 'https://www.seriesfi.com/products/intel',
    productSite: isDev
      ? 'https://intel.series.engineering'
      : 'https://intel.seriesfi.com',
    description:
      'Ask questions across your financial data, documentation, and data stores.',
    testId: 'launch-intel-button',
  },
};

export const MY_APPS = [];

const PRODUCT_SLUGS = {
  banking: 'BANKING',
  billing: 'BILLING',
  workforce: 'WORKFORCE',
  contract: 'CONTRACT',
  vault: 'VAULT',
  intel: 'INTEL',
  treasury: 'TREASURY',
};

export const APPS = {
  [PRODUCT_SLUGS.banking]: APPS_CONFIG.banking,
  [PRODUCT_SLUGS.billing]: APPS_CONFIG.billing,
  [PRODUCT_SLUGS.workforce]: APPS_CONFIG.workForce,
  [PRODUCT_SLUGS.vault]: APPS_CONFIG.vault,
  [PRODUCT_SLUGS.treasury]: APPS_CONFIG.invest,
  [PRODUCT_SLUGS.contract]: APPS_CONFIG.contracts,
  [PRODUCT_SLUGS.intel]: APPS_CONFIG.intel,
};
