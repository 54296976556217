import { Card } from './card';
import {
  AppGrid,
  Divider,
  Header,
  MyApps,
  MyAppsContainer,
  NoApps,
  NoAppsContainer,
  SuggestedAppsContainer,
  SubscribedAppGrid,
} from './styles';
import { useAppsData } from './hooks/useAppsData';

export const Apps = () => {
  const { myProducts, products, isLoading } = useAppsData();

  if (isLoading) return <MyApps />;

  return (
    <>
      <MyApps>
        <Header>Shortcuts</Header>
        <Divider />
        {myProducts.length === 0 ? (
          <NoAppsContainer>
            <NoApps>
              <img src="/info.svg" alt="Info" />
              You haven’t subscribed to any Series products yet. Explore the
              Series suite of applications ...
            </NoApps>
          </NoAppsContainer>
        ) : (
          <MyAppsContainer>
            <SubscribedAppGrid>
              {myProducts.map((app) => (
                <Card key={app.name} data={{ ...app, subscribed: true }} />
              ))}
            </SubscribedAppGrid>
          </MyAppsContainer>
        )}
      </MyApps>
      {products.length > 0 && (
        <SuggestedAppsContainer>
          <Header>Suggested</Header>
          <Divider />
          <AppGrid>
            {products.map((product) => (
              <Card key={product.name} data={product} />
            ))}
          </AppGrid>
        </SuggestedAppsContainer>
      )}
    </>
  );
};
