import styled from 'styled-components';

export const Container = styled.div`
  padding: 30px;
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
`;

export const Title = styled.div`
  margin-top: 30px;
  font-size: 28px;
`;

export const Description = styled.div`
  margin-top: 30px;
  font-size: 15px;
  text-align: center;
`;

export const StyledDescription = styled.div`
  margin-top: 30px;
  font-size: 15px;
  background-color: #fdf3ff;
  padding: 15px;
  display: flex;
  align-items: center;
  cursor: pointer;
  width: 460px;
`;

export const ButtonContainer = styled.div`
  display: flex;
  gap: 10px;
`;

export const ModalButton = styled.div`
  margin-top: 30px;
  height: 54px;
  padding: 0px 30px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  width: 460;
  border-radius: 8px;
  gap: 10px;
  color: white;
  background: #590c6b;
`;

export const StepperContainer = styled.div`
  margin-top: 30px;
  display: flex;
  gap: 10px;
`;

export const Stepper = styled.div`
  border-radius: 50%;
  height: 10px;
  width: 10px;
`;
