import { selectedBusinessSelector } from '@/api/store/slices/businessSlice';
import { Apps } from '@/components/apps';
import { useDefaultBusiness } from '@/components/apps/hooks/useDefaultBusiness';
import {
  Content,
  Header,
  Wrapper,
} from '@/components/common/layout/components';
import NewUserModal from '@/components/users/NewUserModal';
import { useConsumeInvitation } from '@/hooks/useConsumeInvitation';
import { LoadingSpinner } from '@seriesfi/ui';
import { useSelector } from 'react-redux';

export default function Home() {
  const { isLoading } = useDefaultBusiness();
  const selectedBusiness = useSelector(selectedBusinessSelector);

  const { invitation, showNewUserModal } = useConsumeInvitation();

  if (isLoading || !selectedBusiness?.id)
    return (
      <div
        style={{
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          height: '100vh',
        }}
      >
        <LoadingSpinner />
      </div>
    );

  return (
    <Wrapper>
      <Header />
      <Content pageTitle="Home">
        <Apps />
        <NewUserModal role={invitation.role} open={!!showNewUserModal} />
      </Content>
    </Wrapper>
  );
}
