import styled, { css } from "styled-components";

export const SuggestedCard = styled.div`
  display: flex;
  width: 350px;
  max-width: 350px;
  padding: 20px;
  flex-direction: column;
  align-items: flex-start;
  align-self: stretch;
  border-radius: 5px;
  border: 1px solid #e4e5e7;
  background: #fff;
  justify-content: space-between;
`;

export const SubscribedCard = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 20px;
`;

export const LaunchButton = styled.div`
  font-family: ${({ theme }) => theme.font.fontFamily.beausiteClassic};
  color: ${({ theme }) => theme.colors.white};
  display: flex;
  width: 220px;
  height: 54px;
  padding: 0px 30px;
  justify-content: center;
  align-items: center;
  gap: 10px;
  border-radius: 8px;
  background: #590c6b;
  cursor: pointer;
`;

export const Description = styled.div`
  margin-top: 20px;
  font-family: ${({ theme }) => theme.font.fontFamily.beausiteClassic};
`;

export const ButtonContainer = styled.div`
  margin-top: 20px;
  display: flex;
  justify-content: space-around;
  width: 100%;
`;

export const Button = styled.div<{ $primary?: boolean }>`
  display: flex;
  height: 54px;
  padding: ${(props) => (props.$primary ? "0px 30px" : "0px 15px")};
  justify-content: center;
  align-items: center;
  border-radius: 8px;
  background: ${(props) => (props.$primary ? "#eef3f6" : "")};
  font-family: ${({ theme }) => theme.font.fontFamily.beausiteClassic};
  cursor: pointer;
`;
