import { useSelector } from 'react-redux';
import {
  ButtonContainer,
  Description,
  Button,
  SubscribedCard,
  SuggestedCard,
  LaunchButton,
} from './styles';
import { selectedBusinessSelector } from '@/api/store/slices/businessSlice';
import { useAuth } from '@seriesfi/ui';
import { useAnalytics } from '@/hooks/useAnalytics';

export interface ProductInfo {
  icon: any;
  description: string;
  subscribed: boolean;
  name: string;
  marketingSite: string;
  productSite: string;
  comingSoon?: boolean;
  buttonOverride?: string;
  testId?: string;
}

type Props = {
  data: ProductInfo;
};

export const Card = ({ data }: Props) => {
  const {
    icon,
    description,
    subscribed,
    name,
    marketingSite,
    productSite,
    comingSoon,
    buttonOverride,
    testId,
  } = data;

  const selectedBusiness = useSelector(selectedBusinessSelector);
  const { user } = useAuth();

  const { trackEvent } = useAnalytics();

  const onClickLearnMore = () => {
    trackEvent({
      name: 'user_learned',
      data: {
        email: user?.email,
        firstName: user?.firstName,
        businessName: selectedBusiness.legalName,
        productName: name,
        company_id: selectedBusiness.id,
      },
    });

    const w = window?.open(marketingSite, '_blank');
    if (w) {
      w.focus();
    }
  };

  const onClickGetStarted = () => {
    trackEvent({
      name: 'demo_requested',
      data: {
        email: user?.email,
        firstName: user?.firstName,
        businessName: selectedBusiness.legalName,
        productName: name,
        // The company_id field must use snake_case in order to be recognized by PlanHat
        company_id: selectedBusiness.id,
      },
    });

    const w = window?.open(
      'https://cal.com/team/seriesfi/series-demo',
      '_blank',
    );
    if (w) {
      w.focus();
    }
  };

  const onClickLaunch = () => {
    // Launch actual product, check env to go to correct environments
    const productSiteWithQueryParams = selectedBusiness
      ? `${productSite}?businessId=${selectedBusiness.id}`
      : productSite;

    trackEvent({
      name: 'product_launched',
      data: {
        email: user?.email,
        firstName: user?.firstName,
        businessName: selectedBusiness.legalName,
        productName: name,
        company_id: selectedBusiness.id,
      },
    });

    const w = window?.open(productSiteWithQueryParams, '_blank');
    if (w) {
      w.focus();
    }
  };

  let content = (
    <SuggestedCard>
      <div
        style={{
          display: 'flex',
          justifyContent: 'space-between',
          width: '100%',
        }}
      >
        {icon}
        {comingSoon && (
          <div
            style={{
              padding: '7px 10px',
              display: 'flex',
              alignItems: 'center',
              gap: 10,
              borderRadius: 5,
              background: '#DCFAFF',
              fontSize: 10,
              fontWeight: 600,
              color: '#276EF1',
            }}
          >
            COMING SOON
          </div>
        )}
      </div>
      <Description>{description}</Description>
      <ButtonContainer>
        <Button onClick={onClickLearnMore}>Learn More</Button>
        <Button $primary onClick={onClickGetStarted}>
          Request a Demo
        </Button>
      </ButtonContainer>
    </SuggestedCard>
  );

  if (subscribed) {
    content = (
      <SubscribedCard>
        {icon}
        <LaunchButton onClick={onClickLaunch} data-testId={testId}>
          {buttonOverride || `Launch ${name}`}
        </LaunchButton>
      </SubscribedCard>
    );
  }

  return content;
};
