export const WorkForce = (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="223"
    height="42"
    viewBox="0 0 223 42"
    fill="none"
  >
    <g clipPath="url(#clip0_254_3279)">
      <path
        d="M48.3985 11.0158C48.4079 11.9205 48.7472 12.6649 49.4068 13.2209C50.0853 13.7675 51.0182 14.0502 52.215 14.0502C53.4117 14.0502 54.2504 13.7957 54.9195 13.2775C55.598 12.7686 55.9278 12.0524 55.9278 11.1384C55.9278 9.64004 54.9195 8.79193 53.4212 8.49981L51.8192 8.17941C51.4705 8.11345 51.4328 8.08518 51.0936 7.9721C50.924 7.90613 50.8015 7.83075 50.7072 7.76478C50.5565 7.61401 50.3586 7.33131 50.3586 6.98264C50.3586 6.22877 50.9522 5.77645 52.0077 5.77645C53.0631 5.77645 53.7981 6.26647 53.8641 7.19938H55.5885C55.5791 6.37012 55.2587 5.70106 54.6274 5.20162C53.996 4.70219 53.1573 4.44775 52.083 4.44775C51.0088 4.44775 50.1889 4.70219 49.5482 5.22047C48.9074 5.71991 48.587 6.38897 48.587 7.21823C48.587 7.9721 48.9168 8.58462 49.2654 8.90501C49.4445 9.07463 49.6707 9.21598 49.9722 9.35733C50.2643 9.48926 50.4999 9.57407 50.6507 9.61177C50.8015 9.64946 51.0088 9.706 51.2632 9.75312L52.8369 10.0829C53.1479 10.1489 53.2987 10.1866 53.6191 10.3751C53.9677 10.5447 54.1279 10.8651 54.1279 11.308C54.1279 12.1372 53.4212 12.6932 52.2338 12.6932C50.9051 12.6932 50.1701 12.0901 50.123 11.0064H48.3985V11.0158Z"
        fill="#1A1C1E"
      />
      <path
        d="M64.0884 13.9182V12.4481H59.3296V9.7813H63.6455V8.39606H59.3296V6.04964H64.0884V4.57959H57.6617V13.9182H64.0884Z"
        fill="#1A1C1E"
      />
      <path
        d="M67.5562 13.9183V10.4976H70.0157C71.024 10.4976 71.3633 10.9028 71.3633 11.8168V12.7875C71.3633 13.334 71.4104 13.7204 71.5046 13.9183H73.2197V13.8523C73.1066 13.7392 73.0783 13.3152 73.0783 12.844V11.5341C73.0783 10.5353 72.6543 9.70601 71.3538 9.70601V9.64004C72.6543 9.51754 73.2291 8.37731 73.2291 7.31247C73.2291 5.62568 72.1925 4.58911 70.289 4.58911H65.8977V13.9183H67.5656H67.5562ZM67.5562 6.04031H69.8367C70.9109 6.04031 71.4481 6.53033 71.4481 7.50094C71.4481 8.47154 70.7979 9.03695 69.7048 9.03695H67.5562V6.04031Z"
        fill="#1A1C1E"
      />
      <path
        d="M76.8288 4.58911H75.1609V13.9183H76.8288V4.58911Z"
        fill="#1A1C1E"
      />
      <path
        d="M85.5077 13.9182V12.4481H80.7489V9.7813H85.0648V8.39606H80.7489V6.04964H85.5077V4.57959H79.081V13.9182H85.5077Z"
        fill="#1A1C1E"
      />
      <path
        d="M86.5726 11.0158C86.5821 11.9205 86.9213 12.6649 87.5809 13.2209C88.2594 13.7675 89.1923 14.0502 90.3891 14.0502C91.5859 14.0502 92.4245 13.7957 93.0936 13.2775C93.7721 12.7686 94.1019 12.0524 94.1019 11.1384C94.1019 9.64004 93.0936 8.79193 91.5953 8.49981L89.9933 8.17941C89.6447 8.11345 89.607 8.08518 89.2677 7.9721C89.0981 7.90613 88.9756 7.83075 88.8814 7.76478C88.7306 7.61401 88.5327 7.33131 88.5327 6.98264C88.5327 6.22877 89.1169 5.77645 90.1818 5.77645C91.2466 5.77645 91.9722 6.26647 92.0382 7.19938H93.7627C93.7532 6.37012 93.4329 5.70106 92.8015 5.20162C92.1701 4.70219 91.3314 4.44775 90.2572 4.44775C89.1829 4.44775 88.3631 4.70219 87.7223 5.22047C87.0815 5.71991 86.7611 6.38897 86.7611 7.21823C86.7611 7.9721 87.0909 8.58462 87.4396 8.90501C87.6186 9.07463 87.8448 9.21598 88.1463 9.35733C88.4385 9.48926 88.674 9.57407 88.8248 9.61177C88.9756 9.64946 89.1829 9.706 89.4373 9.75312L91.011 10.0829C91.322 10.1489 91.4728 10.1866 91.7932 10.3751C92.1418 10.5447 92.302 10.8651 92.302 11.308C92.302 12.1372 91.5953 12.6932 90.4079 12.6932C89.0793 12.6932 88.3442 12.0901 88.2971 11.0064H86.5726V11.0158Z"
        fill="#1A1C1E"
      />
      <path
        d="M61.8079 25.4621L58.7077 37.1942H53.6756L48.4079 18.9412H53.5436L56.6251 30.5413L59.6217 18.9412H64.2109L67.2358 30.5696L70.3173 18.9412H75.2174L69.9497 37.1942H64.9177L61.8174 25.4621H61.8079Z"
        fill="#1A1C1E"
      />
      <path
        d="M75.6226 27.997C75.6226 21.9472 80.3437 18.583 85.2722 18.583C90.2006 18.583 94.9217 21.8906 94.9217 27.997C94.9217 34.1033 90.276 37.5428 85.2439 37.5428C80.2118 37.5428 75.6226 34.1033 75.6226 27.997ZM89.654 27.997C89.654 24.8401 87.7788 22.8895 85.2722 22.8895C82.7655 22.8895 80.862 24.8213 80.862 27.997C80.862 31.1726 82.7655 33.2081 85.2722 33.2081C87.7788 33.2081 89.654 31.248 89.654 27.997Z"
        fill="#1A1C1E"
      />
      <path
        d="M102.244 31.0972V37.2035H97.2399V18.9504H106.004C110.932 18.9504 112.911 21.7963 112.911 24.9249C112.911 27.554 111.45 29.3821 109.471 30.2208V30.2773C109.679 30.5883 109.782 30.7956 110.093 31.3422L113.458 37.2129H107.643L104.543 31.427C104.411 31.1631 104.336 31.116 103.996 31.116H102.253L102.244 31.0972ZM102.244 27.1299H105.325C106.946 27.1299 107.775 26.2913 107.775 25.0474C107.775 23.8035 106.918 22.9083 105.325 22.9083H102.244V27.1299Z"
        fill="#1A1C1E"
      />
      <path
        d="M123.164 29.9475L120.534 32.9159V37.1941H115.549V18.9504H120.534V26.3289L126.792 18.9504H132.973L126.641 26.0651L133.105 37.2035H127.263L123.173 29.9569L123.164 29.9475Z"
        fill="#1A1C1E"
      />
      <path
        d="M139.956 30.4941V37.1941H134.924V18.9504H148.173V23.2004H139.956V26.5928H147.287V30.5035H139.956V30.4941Z"
        fill="#1A1C1E"
      />
      <path
        d="M149.549 27.997C149.549 21.9472 154.27 18.583 159.199 18.583C164.127 18.583 168.848 21.8906 168.848 27.997C168.848 34.1033 164.202 37.5428 159.17 37.5428C154.138 37.5428 149.549 34.1033 149.549 27.997ZM163.58 27.997C163.58 24.8401 161.705 22.8895 159.199 22.8895C156.692 22.8895 154.788 24.8213 154.788 27.997C154.788 31.1726 156.692 33.2081 159.199 33.2081C161.705 33.2081 163.58 31.248 163.58 27.997Z"
        fill="#1A1C1E"
      />
      <path
        d="M176.17 31.0972V37.2035H171.166V18.9504H179.93C184.858 18.9504 186.837 21.7963 186.837 24.9249C186.837 27.554 185.377 29.3821 183.398 30.2208V30.2773C183.605 30.5883 183.709 30.7956 184.02 31.3422L187.384 37.2129H181.57L178.469 31.427C178.337 31.1631 178.262 31.116 177.923 31.116H176.18L176.17 31.0972ZM176.17 27.1299H179.252C180.872 27.1299 181.702 26.2913 181.702 25.0474C181.702 23.8035 180.844 22.9083 179.252 22.9083H176.17V27.1299Z"
        fill="#1A1C1E"
      />
      <path
        d="M188.317 27.997C188.317 21.9754 192.981 18.583 197.702 18.583C202.424 18.583 205.92 21.2969 206.41 25.4432H201.274C200.878 23.8789 199.549 22.8895 197.674 22.8895C195.375 22.8895 193.556 24.8213 193.556 27.997C193.556 31.1726 195.403 33.2081 197.702 33.2081C199.502 33.2081 200.803 32.2658 201.302 30.6261H206.438C205.863 34.7441 202.235 37.5334 197.646 37.5334C192.774 37.5334 188.307 34.0656 188.307 27.9875L188.317 27.997Z"
        fill="#1A1C1E"
      />
      <path
        d="M222.533 37.2035H208.709V18.9504H222.373V22.9177H213.666V25.9991H221.647V29.8344H213.666V33.2457H222.533V37.2129V37.2035Z"
        fill="#1A1C1E"
      />
      <path
        d="M20.9953 0C9.40453 0 0 9.40453 0 20.9953C0 32.586 9.40453 41.9906 20.9953 41.9906C32.586 41.9906 41.9906 32.586 41.9906 20.9953C41.9906 9.40453 32.5955 0 20.9953 0Z"
        fill="#57EADB"
      />
      <path
        d="M20.9953 21.3816C23.5679 21.3816 25.6504 19.299 25.6504 16.7264C25.6504 14.1539 23.5679 12.0713 20.9953 12.0713C18.4227 12.0713 16.3401 14.1539 16.3401 16.7264C16.3401 19.299 18.4227 21.3816 20.9953 21.3816ZM20.9953 15.181C21.8528 15.181 22.5502 15.8783 22.5502 16.7359C22.5502 17.5934 21.8528 18.2907 20.9953 18.2907C20.1378 18.2907 19.4404 17.5934 19.4404 16.7359C19.4404 15.8783 20.1378 15.181 20.9953 15.181Z"
        fill="#044741"
      />
      <path
        d="M15.9632 29.9097C18.5358 29.9097 20.6183 27.8271 20.6183 25.2545C20.6183 22.6819 18.5358 20.5994 15.9632 20.5994C13.3906 20.5994 11.308 22.6819 11.308 25.2545C11.308 27.8271 13.3906 29.9097 15.9632 29.9097ZM15.9632 23.7091C16.8207 23.7091 17.5181 24.4064 17.5181 25.2639C17.5181 26.1215 16.8207 26.8188 15.9632 26.8188C15.1057 26.8188 14.4083 26.1215 14.4083 25.2639C14.4083 24.4064 15.1057 23.7091 15.9632 23.7091Z"
        fill="#044741"
      />
      <path
        d="M26.0368 29.9097C28.6094 29.9097 30.692 27.8271 30.692 25.2545C30.692 22.6819 28.6094 20.5994 26.0368 20.5994C23.4642 20.5994 21.3817 22.6819 21.3817 25.2545C21.3817 27.8271 23.4642 29.9097 26.0368 29.9097ZM26.0368 23.7091C26.8943 23.7091 27.5917 24.4064 27.5917 25.2639C27.5917 26.1215 26.8943 26.8188 26.0368 26.8188C25.1793 26.8188 24.4819 26.1215 24.4819 25.2639C24.4819 24.4064 25.1793 23.7091 26.0368 23.7091Z"
        fill="#044741"
      />
    </g>
    <defs>
      <clipPath id="clip0_254_3279">
        <rect width="222.533" height="42" fill="white" />
      </clipPath>
    </defs>
  </svg>
);
