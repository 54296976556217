export const Banking = (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="123"
    height="42"
    viewBox="0 0 123 42"
    fill="none"
  >
    <g clipPath="url(#clip0_613_371)">
      <path
        d="M21 0C9.40321 0 0 9.40321 0 21C0 32.5968 9.40321 42 21 42C32.5968 42 42 32.5968 42 21C42 9.40321 32.5993 0 21 0Z"
        fill="#BBE4FF"
      />
      <path
        d="M48.4069 11.0291C48.4196 11.9393 48.752 12.6807 49.4193 13.2304C50.0968 13.7801 51.0325 14.0638 52.2239 14.0638C53.4153 14.0638 54.2589 13.8082 54.9262 13.2943C55.6037 12.783 55.9386 12.0646 55.9386 11.157C55.9386 9.6588 54.9262 8.81256 53.428 8.51855L51.8276 8.19897C51.4825 8.13506 51.4441 8.10949 51.099 7.99445C50.9328 7.93053 50.805 7.85383 50.7155 7.78992C50.5621 7.63652 50.3703 7.35529 50.3703 7.00759C50.3703 6.25084 50.9583 5.80343 52.0219 5.80343C53.0854 5.80343 53.8141 6.28919 53.878 7.22491H55.6063C55.5935 6.39145 55.2739 5.72673 54.645 5.22819C54.0186 4.72965 53.1724 4.47144 52.096 4.47144C51.0197 4.47144 50.2016 4.7271 49.5599 5.24098C48.9207 5.73951 48.5986 6.40679 48.5986 7.23769C48.5986 7.99445 48.931 8.60803 49.2761 8.92761C49.4551 9.09379 49.6852 9.2344 49.9792 9.37501C50.2732 9.50284 50.5033 9.59233 50.6567 9.63068C50.8101 9.66902 51.0146 9.72016 51.2703 9.77129L52.8451 10.1036C53.1519 10.1676 53.3053 10.2059 53.6275 10.3977C53.9726 10.5638 54.1388 10.8834 54.1388 11.3334C54.1388 12.1668 53.4357 12.7165 52.2443 12.7165C50.9123 12.7165 50.1837 12.1157 50.1326 11.0266H48.4043L48.4069 11.0291Z"
        fill="#1A1C1E"
      />
      <path
        d="M64.0942 13.9334V12.4608H59.3312V9.79683H63.6519V8.4137H59.3312V6.06929H64.0942V4.59668H57.6669V13.9334H64.0942Z"
        fill="#1A1C1E"
      />
      <path
        d="M67.5635 13.9336V10.5154H70.023C71.0354 10.5154 71.3678 10.9244 71.3678 11.8346V12.8086C71.3678 13.3583 71.4189 13.7444 71.5084 13.9361H73.2239V13.8696C73.1114 13.7571 73.0832 13.3353 73.0832 12.8598V11.5533C73.0832 10.5537 72.6563 9.72281 71.3601 9.72281V9.65634C72.6563 9.53874 73.2366 8.39082 73.2366 7.32727C73.2366 5.63735 72.1987 4.59937 70.2914 4.59937H65.8992V13.9336H67.5635ZM67.5635 6.05919H69.844C70.9204 6.05919 71.4572 6.54495 71.4572 7.51901C71.4572 8.49308 70.8053 9.05554 69.7162 9.05554H67.5661V6.05919H67.5635Z"
        fill="#1A1C1E"
      />
      <path
        d="M76.8338 4.59937H75.1694V13.9336H76.8338V4.59937Z"
        fill="#1A1C1E"
      />
      <path
        d="M85.5161 13.9334V12.4608H80.7531V9.79683H85.0738V8.4137H80.7531V6.06929H85.5161V4.59668H79.0887V13.9334H85.5161Z"
        fill="#1A1C1E"
      />
      <path
        d="M86.5796 11.0291C86.5924 11.9393 86.9247 12.6807 87.592 13.2304C88.2695 13.7801 89.2052 14.0638 90.3966 14.0638C91.588 14.0638 92.4317 13.8082 93.0989 13.2943C93.7764 12.783 94.1114 12.0646 94.1114 11.157C94.1114 9.6588 93.0989 8.81256 91.6008 8.51855L90.0003 8.19897C89.6552 8.13506 89.6168 8.10949 89.2717 7.99445C89.1055 7.93053 88.9777 7.85383 88.8882 7.78992C88.7348 7.63652 88.5431 7.35529 88.5431 7.00759C88.5431 6.25084 89.1311 5.80343 90.1946 5.80343C91.2582 5.80343 91.9868 6.28919 92.0507 7.22491H93.779C93.7662 6.39145 93.4466 5.72673 92.8177 5.22819C92.1913 4.72965 91.3451 4.47144 90.2688 4.47144C89.1924 4.47144 88.3743 4.7271 87.7326 5.24098C87.0935 5.73951 86.7713 6.40679 86.7713 7.23769C86.7713 7.99445 87.1037 8.60803 87.4488 8.92761C87.6278 9.09379 87.8579 9.2344 88.1519 9.37501C88.4459 9.50284 88.676 9.59233 88.8294 9.63068C88.9828 9.66902 89.1873 9.72016 89.443 9.77129L91.0179 10.1036C91.3247 10.1676 91.478 10.2059 91.8002 10.3977C92.1453 10.5638 92.3115 10.8834 92.3115 11.3334C92.3115 12.1668 91.6084 12.7165 90.4171 12.7165C89.0851 12.7165 88.3564 12.1157 88.3053 11.0266H86.577L86.5796 11.0291Z"
        fill="#1A1C1E"
      />
      <path
        d="M48.4069 28.0101C48.4069 21.9867 53.0752 18.5967 57.7947 18.5967C62.5142 18.5967 66.0091 21.3092 66.5051 25.4561H61.3689C60.9777 23.8914 59.6483 22.8995 57.7692 22.8995C55.4733 22.8995 53.6479 24.8297 53.6479 28.0101C53.6479 31.1905 55.4989 33.2256 57.7947 33.2256C59.5946 33.2256 60.8985 32.2873 61.3944 30.6434H66.5332C65.9605 34.7647 62.3353 37.5539 57.7436 37.5539C52.8681 37.5539 48.4069 34.0846 48.4069 28.0101Z"
        fill="#1A1C1E"
      />
      <path
        d="M80.1446 33.7473H73.2341L72.0862 37.2166H66.8962L73.6508 18.9624H79.935L86.6895 37.2166H81.29L80.142 33.7473H80.1446ZM78.8152 29.8101L76.6753 23.4211L74.5635 29.8101H78.8152Z"
        fill="#1A1C1E"
      />
      <path
        d="M87.1599 31.4003H92.1939C92.4035 33.0953 93.6793 33.8521 95.4024 33.8521C96.9415 33.8521 98.0357 33.2513 98.0357 32.1315C98.0357 31.1676 97.1742 30.6717 95.4024 30.3061L93.6026 29.9405C89.4302 29.107 87.5255 27.2535 87.5255 24.385C87.5255 20.9438 90.8644 18.5942 94.9576 18.5942C99.0507 18.5942 102.313 20.6549 102.702 24.385H97.7213C97.5116 23.0018 96.443 22.2732 94.9039 22.2732C93.5489 22.2732 92.5058 22.9507 92.5058 23.9427C92.5058 24.8298 93.1833 25.1954 94.8016 25.5073L96.7574 25.8985C100.643 26.6552 103.067 28.3247 103.067 31.6611C103.067 35.4167 99.4956 37.5541 95.3487 37.5541C90.9667 37.5541 87.5511 35.5983 87.1599 31.4003Z"
        fill="#1A1C1E"
      />
      <path
        d="M105.44 18.9624H110.578V25.6633H117.384V18.9624H122.549V37.2166H117.384V29.8894H110.578V37.2166H105.44V18.9624Z"
        fill="#1A1C1E"
      />
      <path
        d="M27.4043 23.8045C27.4043 21.1942 25.5073 19.8878 22.4675 19.2972L20.9387 18.9904C19.6731 18.745 19.1439 18.4586 19.1439 17.7658C19.1439 16.9911 19.9595 16.4594 21.0205 16.4594C22.2221 16.4594 23.0606 17.0295 23.2217 18.1109H27.118C26.8674 15.7128 25.1366 14.1993 22.7155 13.7366V11.2822H19.6476V13.7084C17.1242 14.161 15.2451 15.8279 15.2451 18.1135C15.2451 20.3991 16.7356 21.8052 19.9978 22.4572L21.404 22.741C22.7896 23.0273 23.4646 23.4159 23.4646 24.1701C23.4646 25.047 22.6081 25.5174 21.404 25.5174C20.0592 25.5174 19.0595 24.9269 18.8959 23.6H14.9587C15.2118 26.31 17.0756 27.8235 19.645 28.2709V30.7176H22.7129V28.3144C25.3437 27.913 27.3992 26.3253 27.3992 23.8071L27.4043 23.8045Z"
        fill="#143566"
      />
    </g>
    <defs>
      <clipPath id="clip0_613_371">
        <rect width="122.546" height="42" fill="white" />
      </clipPath>
    </defs>
  </svg>
);
