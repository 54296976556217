import { selectedBusinessSelector } from '@/api/store/slices/businessSlice';
import { useState } from 'react';
import { useSelector } from 'react-redux';
import ConfirmationModal from '../../common/Modal';
import { useRouter } from 'next/router';
import {
  ButtonContainer,
  Container,
  Description,
  ModalButton,
  Stepper,
  StepperContainer,
  StyledDescription,
  Title,
} from './styles';

const customStyles = {
  content: {
    top: '50%',
    left: '50%',
    right: 'auto',
    bottom: 'auto',
    marginRight: '-50%',
    transform: 'translate(-50%, -50%)',
    borderRadius: 10,
    border: '1px solid #e4e5e7',
    maxWidth: 525,
  },
};

const NewUserModal = ({ role, open }: { role: string; open: boolean }) => {
  const [step, setStep] = useState(0);
  const currentBusiness = useSelector(selectedBusinessSelector);
  const router = useRouter();

  const closeModal = () => {
    router.push(`?businessId=${currentBusiness.id}`);
  };

  const stepOne = (
    <Container>
      <img src="/congrats.svg" style={{ height: 180, width: 460 }} />

      <Title>Congratulations!</Title>
      <Description>
        You've been added to {currentBusiness.legalName}
        {role && ` as a/n ${role}`}.
      </Description>

      <StyledDescription onClick={() => setStep(1)}>
        <img
          src="/lightbulb.svg"
          style={{ height: 36, width: 36, marginRight: 10 }}
        />
        Learn more about permissions and navigating between businesses.
        <img
          src="/rightArrow.svg"
          style={{ height: 24, width: 24, marginLeft: 10 }}
        />
      </StyledDescription>

      <ModalButton onClick={closeModal}>Done</ModalButton>
    </Container>
  );

  const stepTwo = (
    <Container>
      <img src="/permissions.svg" style={{ height: 270, width: 460 }} />

      <Title>View & manage permissions</Title>

      <Description>
        You can view access and permissions for the selected business in the
        Users tab.
      </Description>

      <StepperContainer>
        <Stepper
          style={{
            backgroundColor: '#590C6B',
          }}
        />
        <Stepper
          style={{
            backgroundColor: '#D9D9D9',
          }}
        />
      </StepperContainer>

      <ButtonContainer>
        <ModalButton
          style={{
            background: '#EEF3F6',
            color: 'black',
          }}
          onClick={closeModal}
        >
          Cancel
        </ModalButton>
        <ModalButton onClick={() => setStep(2)}>Continue</ModalButton>
      </ButtonContainer>
    </Container>
  );

  const stepThree = (
    <Container>
      <img src="/businessNav.svg" style={{ height: 270, width: 460 }} />

      <Title>Business Navigation</Title>

      <Description>
        You can navigate between businesses to which you have access in the
        primary navigation.
      </Description>

      <StepperContainer>
        <Stepper
          style={{
            backgroundColor: '#D9D9D9',
          }}
        />
        <Stepper
          style={{
            backgroundColor: '#590C6B',
          }}
        />
      </StepperContainer>

      <ButtonContainer>
        <ModalButton
          style={{
            background: '#EEF3F6',
            color: 'black',
          }}
          onClick={() => setStep(1)}
        >
          Back
        </ModalButton>
        <ModalButton onClick={closeModal}>Done</ModalButton>
      </ButtonContainer>
    </Container>
  );

  let content = stepOne;

  if (step === 1) {
    content = stepTwo;
  } else if (step === 2) {
    content = stepThree;
  }

  return (
    <ConfirmationModal
      isOpen={open}
      styleOverride={customStyles}
      onRequestClose={closeModal}
    >
      {content}
    </ConfirmationModal>
  );
};

export default NewUserModal;
