export const Vault = (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="133"
    height="42"
    viewBox="0 0 133 42"
    fill="none"
  >
    <g clipPath="url(#clip0_254_3308)">
      <path
        d="M20.9953 0C9.40453 0 0 9.40453 0 20.9953C0 32.586 9.40453 41.9906 20.9953 41.9906C32.586 41.9906 41.9906 32.586 41.9906 20.9953C41.9906 9.40453 32.5955 0 20.9953 0Z"
        fill="#64DEF1"
      />
      <path
        d="M48.3985 11.0254C48.4079 11.93 48.7472 12.6745 49.4068 13.2304C50.0853 13.777 51.0182 14.0597 52.215 14.0597C53.4117 14.0597 54.2504 13.8053 54.9195 13.287C55.598 12.7781 55.9278 12.0619 55.9278 11.1479C55.9278 9.64956 54.9195 8.80145 53.4212 8.50933L51.8192 8.18893C51.4705 8.12297 51.4328 8.0947 51.0936 7.98162C50.924 7.91566 50.8015 7.84027 50.7072 7.7743C50.5565 7.62353 50.3586 7.34083 50.3586 6.99216C50.3586 6.23829 50.9522 5.78597 52.0077 5.78597C53.0631 5.78597 53.7981 6.27599 53.8641 7.2089H55.5885C55.5791 6.37964 55.2587 5.71058 54.6274 5.21115C53.996 4.71171 53.1573 4.45728 52.083 4.45728C51.0088 4.45728 50.1889 4.71171 49.5482 5.22999C48.9074 5.72943 48.587 6.39849 48.587 7.22775C48.587 7.98162 48.9168 8.59414 49.2654 8.91453C49.4445 9.08415 49.6707 9.2255 49.9722 9.36686C50.2643 9.49878 50.4999 9.58359 50.6507 9.62129C50.8015 9.65898 51.0088 9.71552 51.2632 9.76264L52.8369 10.0925C53.1479 10.1584 53.2987 10.1961 53.6191 10.3846C53.9677 10.5542 54.1279 10.8746 54.1279 11.3175C54.1279 12.1468 53.4212 12.7027 52.2338 12.7027C50.9051 12.7027 50.1701 12.0996 50.123 11.0159H48.3985V11.0254Z"
        fill="#1A1C1E"
      />
      <path
        d="M64.0884 13.9372V12.4672H59.3296V9.80034H63.6455V8.4151H59.3296V6.06868H64.0884V4.59863H57.6617V13.9372H64.0884Z"
        fill="#1A1C1E"
      />
      <path
        d="M67.5562 13.9373V10.5166H70.0157C71.024 10.5166 71.3633 10.9218 71.3633 11.8359V12.8065C71.3633 13.3531 71.4104 13.7394 71.5046 13.9373H73.2197V13.8713C73.1066 13.7583 73.0783 13.3342 73.0783 12.863V11.5532C73.0783 10.5543 72.6543 9.72505 71.3538 9.72505V9.65909C72.6543 9.53658 73.2291 8.39635 73.2291 7.33151C73.2291 5.64473 72.1925 4.60815 70.289 4.60815H65.8977V13.9373H67.5656H67.5562ZM67.5562 6.05935H69.8367C70.9109 6.05935 71.4481 6.54937 71.4481 7.51998C71.4481 8.49059 70.7979 9.05599 69.7048 9.05599H67.5562V6.05935Z"
        fill="#1A1C1E"
      />
      <path
        d="M76.8288 4.59863H75.1609V13.9372H76.8288V4.59863Z"
        fill="#1A1C1E"
      />
      <path
        d="M85.5077 13.9372V12.4672H80.7489V9.80034H85.0648V8.4151H80.7489V6.06868H85.5077V4.59863H79.081V13.9372H85.5077Z"
        fill="#1A1C1E"
      />
      <path
        d="M86.5726 11.0254C86.5821 11.93 86.9213 12.6745 87.5809 13.2304C88.2594 13.777 89.1923 14.0597 90.3891 14.0597C91.5859 14.0597 92.4245 13.8053 93.0936 13.287C93.7721 12.7781 94.1019 12.0619 94.1019 11.1479C94.1019 9.64956 93.0936 8.80145 91.5953 8.50933L89.9933 8.18893C89.6447 8.12297 89.607 8.0947 89.2677 7.98162C89.0981 7.91566 88.9756 7.84027 88.8814 7.7743C88.7306 7.62353 88.5327 7.34083 88.5327 6.99216C88.5327 6.23829 89.1169 5.78597 90.1818 5.78597C91.2466 5.78597 91.9722 6.27599 92.0382 7.2089H93.7627C93.7532 6.37964 93.4329 5.71058 92.8015 5.21115C92.1701 4.71171 91.3314 4.45728 90.2572 4.45728C89.1829 4.45728 88.3631 4.71171 87.7223 5.22999C87.0815 5.72943 86.7611 6.39849 86.7611 7.22775C86.7611 7.98162 87.0909 8.59414 87.4396 8.91453C87.6186 9.08415 87.8448 9.2255 88.1463 9.36686C88.4385 9.49878 88.674 9.58359 88.8248 9.62129C88.9756 9.65898 89.1829 9.71552 89.4373 9.76264L91.011 10.0925C91.322 10.1584 91.4728 10.1961 91.7932 10.3846C92.1418 10.5542 92.302 10.8746 92.302 11.3175C92.302 12.1468 91.5953 12.7027 90.4079 12.7027C89.0793 12.7027 88.3442 12.0996 88.2971 11.0159H86.5726V11.0254Z"
        fill="#1A1C1E"
      />
      <path
        d="M20.9953 11.2798L11.2704 15.9444V19.8362C11.2704 24.4725 14.3801 28.779 20.9953 30.7202C27.6105 28.779 30.7202 24.4725 30.7202 19.8362V15.9444L20.9953 11.2798ZM14.3801 19.8362V18.3473L20.9953 15.1716V27.4597C16.7831 25.9803 14.3801 23.2381 14.3801 19.8362Z"
        fill="#083D44"
      />
      <path
        d="M60.3473 37.213H54.8158L48.3985 18.96H53.8264L57.7088 31.4271L61.5724 18.96H66.7647L60.3473 37.213Z"
        fill="#1A1C1E"
      />
      <path
        d="M78.6004 33.7452H71.6931L70.5434 37.213H65.3511L72.1077 18.96H78.3931L85.1497 37.213H79.7501L78.6004 33.7452ZM77.2717 29.8063L75.1326 23.4172L73.0218 29.8063H77.2717Z"
        fill="#1A1C1E"
      />
      <path
        d="M86.0072 29.8816V18.9599H91.1429V29.5706C91.1429 31.917 92.3963 33.2174 94.2998 33.2174C96.2033 33.2174 97.4566 31.917 97.4566 29.5894V18.9504H102.592V29.8533C102.592 34.7252 99.1717 37.5239 94.2715 37.5239C89.3714 37.5239 86.0072 34.7817 86.0072 29.8816Z"
        fill="#1A1C1E"
      />
      <path
        d="M105.636 18.96H110.772V32.9631H118.697V37.213H105.636V18.96Z"
        fill="#1A1C1E"
      />
      <path
        d="M121.91 23.2099H116.614V18.96H132.361V23.2099H127.037V37.213H121.901V23.2099H121.91Z"
        fill="#1A1C1E"
      />
    </g>
    <defs>
      <clipPath id="clip0_254_3308">
        <rect width="132.361" height="42" fill="white" />
      </clipPath>
    </defs>
  </svg>
);
