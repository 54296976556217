import { useConsumeInvitationMutation } from '@/api/invitationApi';
import { useGetSessionDataQuery } from '@/api/sessionApi';
import { useRouter } from 'next/router';
import { useEffect } from 'react';

export const useConsumeInvitation = () => {
  const router = useRouter();
  const invitationCode = (router.query.invitationCode as string) || '';
  const showNewUserModal = router.query.acceptInvite as string;

  const [consumeInvitation, result] = useConsumeInvitationMutation();
  const { refetch } = useGetSessionDataQuery('');

  useEffect(() => {
    if (invitationCode) {
      consumeInvitation({ code: invitationCode });
    }
  }, [invitationCode]);

  useEffect(() => {
    if (result.isSuccess) {
      refetch();
      router.push(
        `?businessId=${result.data.data.businessId}&acceptInvite=true`,
      );
    }
  }, [result]);

  return {
    invitation: { role: result?.data?.data?.role },
    showNewUserModal,
  };
};
