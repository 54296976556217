import styled, { css } from "styled-components";

export const MyApps = styled.div``;

export const Header = styled.div`
  font-size: 20px;
  font-weight: 700;
  font-family: "Beausite Classic";
`;

export const MyAppsContainer = styled.div`
  display: flex;
  gap: 20px;
  padding: 30px 0px;
`;

export const SuggestedAppsContainer = styled.div`
  margin-left: -32px !important;
  display: flex;
  flex-direction: column;
  background: #fafafb;
  width: calc(100% + 64px);
  padding: 32px;
  border-top: 1px solid #e4e5e7;
`;

export const NoAppsContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 30px;
`;

export const NoApps = styled.div`
  max-width: 487px;
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const Divider = styled.div`
  width: 100%;
  border-bottom: 1px solid #e4e5e7;
  margin-top: 10px;
`;

export const AppGrid = styled.div`
  margin-top: 30px;
  display: flex;
  justify-content: flex-start;
  flex-wrap: wrap;
  gap: 20px;
`;

export const SubscribedAppGrid = styled.div`
  display: flex;
  justify-content: flex-start;
  flex-wrap: wrap;
  gap: 30px 60px;
`;
