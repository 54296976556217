export const Treasury = (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="144"
    height="42"
    viewBox="0 0 144 42"
    fill="none"
  >
    <g clipPath="url(#clip0_613_386)">
      <path
        d="M21 0C9.40321 0 0 9.40321 0 21C0 32.5968 9.40321 42 21 42C32.5968 42 42 32.5968 42 21C42 9.40321 32.5993 0 21 0Z"
        fill="#FF9FDF"
      />
      <path
        d="M48.2253 10.9552C48.2381 11.8653 48.5705 12.6067 49.2378 13.1564C49.9153 13.7061 50.851 13.9899 52.0424 13.9899C53.2337 13.9899 54.0774 13.7342 54.7447 13.2203C55.4222 12.709 55.7571 11.9906 55.7571 11.083C55.7571 9.58482 54.7447 8.73859 53.2465 8.44458L51.6461 8.125C51.3009 8.06108 51.2626 8.03552 50.9174 7.92047C50.7513 7.85655 50.6234 7.77986 50.534 7.71594C50.3806 7.56254 50.1888 7.28132 50.1888 6.93362C50.1888 6.17686 50.7768 5.72945 51.8404 5.72945C52.9039 5.72945 53.6326 6.21521 53.6965 7.15093H55.4248C55.412 6.31748 55.0924 5.65276 54.4635 5.15422C53.8371 4.65568 52.9909 4.39746 51.9145 4.39746C50.8382 4.39746 50.0201 4.65312 49.3784 5.167C48.7392 5.66554 48.4171 6.33282 48.4171 7.16371C48.4171 7.92047 48.7494 8.53406 49.0946 8.85363C49.2735 9.01981 49.5036 9.16043 49.7977 9.30104C50.0917 9.42887 50.3218 9.51835 50.4752 9.5567C50.6285 9.59505 50.8331 9.64618 51.0887 9.69731L52.6636 10.0297C52.9704 10.0936 53.1238 10.1319 53.4459 10.3237C53.7911 10.4899 53.9573 10.8094 53.9573 11.2594C53.9573 12.0929 53.2542 12.6425 52.0628 12.6425C50.7308 12.6425 50.0022 12.0417 49.951 10.9526H48.2228L48.2253 10.9552Z"
        fill="#1A1C1E"
      />
      <path
        d="M63.9101 13.8621V12.3895H59.1472V9.72554H63.4678V8.34241H59.1472V5.998H63.9101V4.52539H57.4828V13.8621H63.9101Z"
        fill="#1A1C1E"
      />
      <path
        d="M67.3795 13.862V10.4438H69.8389C70.8514 10.4438 71.1837 10.8529 71.1837 11.763V12.7371C71.1837 13.2868 71.2348 13.6728 71.3243 13.8646H73.0398V13.7981C72.9273 13.6856 72.8992 13.2638 72.8992 12.7882V11.4818C72.8992 10.4822 72.4722 9.65128 71.176 9.65128V9.58481C72.4722 9.4672 73.0526 8.31929 73.0526 7.25574C73.0526 5.56582 72.0146 4.52783 70.1074 4.52783H65.7151V13.862H67.3795ZM67.3795 5.98766H69.66C70.7363 5.98766 71.2732 6.47341 71.2732 7.44748C71.2732 8.42155 70.6213 8.984 69.5321 8.984H67.382V5.98766H67.3795Z"
        fill="#1A1C1E"
      />
      <path
        d="M76.6497 4.52783H74.9854V13.862H76.6497V4.52783Z"
        fill="#1A1C1E"
      />
      <path
        d="M85.332 13.8621V12.3895H80.569V9.72554H84.8897V8.34241H80.569V5.998H85.332V4.52539H78.9047V13.8621H85.332Z"
        fill="#1A1C1E"
      />
      <path
        d="M86.3955 10.9552C86.4083 11.8653 86.7407 12.6067 87.4079 13.1564C88.0855 13.7061 89.0212 13.9899 90.2125 13.9899C91.4039 13.9899 92.2476 13.7342 92.9149 13.2203C93.5924 12.709 93.9273 11.9906 93.9273 11.083C93.9273 9.58482 92.9149 8.73859 91.4167 8.44458L89.8163 8.125C89.4711 8.06108 89.4328 8.03552 89.0876 7.92047C88.9215 7.85655 88.7936 7.77986 88.7041 7.71594C88.5507 7.56254 88.359 7.28132 88.359 6.93362C88.359 6.17686 88.947 5.72945 90.0106 5.72945C91.0741 5.72945 91.8028 6.21521 91.8667 7.15093H93.5949C93.5822 6.31748 93.2626 5.65276 92.6337 5.15422C92.0073 4.65568 91.161 4.39746 90.0847 4.39746C89.0084 4.39746 88.1903 4.65312 87.5486 5.167C86.9094 5.66554 86.5873 6.33282 86.5873 7.16371C86.5873 7.92047 86.9196 8.53406 87.2648 8.85363C87.4437 9.01981 87.6738 9.16043 87.9678 9.30104C88.2619 9.42887 88.492 9.51835 88.6453 9.5567C88.7987 9.59505 89.0033 9.64618 89.2589 9.69731L90.8338 10.0297C91.1406 10.0936 91.294 10.1319 91.6161 10.3237C91.9613 10.4899 92.1275 10.8094 92.1275 11.2594C92.1275 12.0929 91.4244 12.6425 90.233 12.6425C88.901 12.6425 88.1724 12.0417 88.1212 10.9526H86.393L86.3955 10.9552Z"
        fill="#1A1C1E"
      />
      <path
        d="M48.2253 18.8909H53.3616V37.1451H48.2253V18.8909Z"
        fill="#1A1C1E"
      />
      <path
        d="M61.4711 25.827V37.1451H56.5957V18.8909H62.4631L69.008 30.209V18.8909H73.8835V37.1451H68.0161L61.4711 25.827Z"
        fill="#1A1C1E"
      />
      <path
        d="M87.523 37.1451H81.9956L75.5811 18.8909H81.0062L84.8923 31.3569L88.7527 18.8909H93.9427L87.5281 37.1451H87.523Z"
        fill="#1A1C1E"
      />
      <path
        d="M109.454 37.1451H95.6326V18.8909H109.298V22.8536H100.587V25.9318H108.566V29.7667H100.587V33.1823H109.454V37.1451Z"
        fill="#1A1C1E"
      />
      <path
        d="M111.225 31.3288H116.259C116.469 33.0238 117.745 33.7806 119.468 33.7806C121.007 33.7806 122.101 33.1798 122.101 32.06C122.101 31.0961 121.24 30.6001 119.468 30.2345L117.668 29.8689C113.496 29.0355 111.591 27.1819 111.591 24.3134C111.591 20.8722 114.93 18.5227 119.023 18.5227C123.116 18.5227 126.378 20.5833 126.767 24.3134H121.787C121.577 22.9303 120.508 22.2017 118.969 22.2017C117.614 22.2017 116.571 22.8792 116.571 23.8711C116.571 24.7583 117.249 25.1239 118.867 25.4358L120.823 25.8269C124.709 26.5837 127.133 28.2532 127.133 31.5895C127.133 35.3452 123.561 37.4825 119.414 37.4825C115.032 37.4825 111.617 35.5267 111.225 31.3288Z"
        fill="#1A1C1E"
      />
      <path
        d="M133.314 23.1398H128.02V18.8882H143.771V23.1398H138.451V37.1449H133.314V23.1398Z"
        fill="#1A1C1E"
      />
      <path
        d="M30.7151 11.2849H25.6019L27.1359 12.8189L22.0226 17.9321L19.7217 15.6312L17.6764 13.5859L15.6311 15.6312L11.2849 19.9774L13.3302 22.0227L17.6764 17.6764L22.0226 22.0227L24.0679 19.9774L29.1812 14.8642L30.7151 16.3981V11.2849Z"
        fill="#63083C"
      />
      <path
        d="M30.7151 23.0454H25.6019V30.7152H30.7151V23.0454Z"
        fill="#63083C"
      />
      <path
        d="M23.5566 25.6021H18.4434V30.7153H23.5566V25.6021Z"
        fill="#63083C"
      />
      <path
        d="M16.3981 28.1584H11.2849V30.7151H16.3981V28.1584Z"
        fill="#63083C"
      />
    </g>
    <defs>
      <clipPath id="clip0_613_386">
        <rect width="143.771" height="42" fill="white" />
      </clipPath>
    </defs>
  </svg>
);
