import { useGetBusinessesProductsQuery } from '@/api/businessApi';
import { useGetProductsQuery } from '@/api/productApi';
import { ProductInfo } from '../card';
import { APPS } from '../constants';
import { selectedBusinessSelector } from '@/api/store/slices/businessSlice';
import { useSelector } from 'react-redux';

interface Product {
  name: string;
  slug: string;
}

export const useAppsData = (): {
  myProducts: ProductInfo[];
  products: ProductInfo[];
  isLoading: boolean;
} => {
  const business = useSelector(selectedBusinessSelector);
  const { data: productsData, isLoading: productsIsLoading } =
    useGetProductsQuery('');
  const {
    data: businessesProductsData,
    isLoading: businessesProductsIsLoading,
  } = useGetBusinessesProductsQuery(business.id);

  const allProducts: Product[] = productsData?.data || [];
  const subscribedProducts = businessesProductsData?.data || {};

  const myProducts: ProductInfo[] = [];
  const products: ProductInfo[] = [];

  allProducts.forEach((product) => {
    const productInfo = APPS[product.slug];
    if (subscribedProducts[productInfo?.subscriptionKey]) {
      myProducts.push(productInfo);
    } else if (productInfo) {
      products.push(productInfo);
    }
  });

  const isLoading = productsIsLoading || businessesProductsIsLoading;

  return { myProducts, products, isLoading };
};
