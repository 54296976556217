import { AnalyticsBrowser } from '@segment/analytics-next';

const ANALYTICS_ENABLED = process.env.NEXT_PUBLIC_ANALYTICS_ENABLED === 'true';

export interface EventProperties {
  name: string;
  data?: Record<string, unknown>;
}

const PRODUCT_NAME = 'NUCLEUS';

export const useSegment = ({ apiKey }: { apiKey: string }) => {
  const analytics = AnalyticsBrowser.load({
    writeKey: apiKey || '',
  });

  const identify = (userId: string) => {
    if (ANALYTICS_ENABLED) {
      analytics?.identify(userId);
    } else {
      console.log('Identify user', userId);
    }
  };

  const track = (
    name: string,
    details: Record<string, unknown>,
    source: string,
  ) => {
    if (ANALYTICS_ENABLED) {
      const date = new Date();
      analytics.track(name, { ...details, createdAt: date, source });
    } else {
      console.log('Track event', name, details, source);
    }
  };

  return { identify, track };
};

export const useAnalytics = () => {
  const { identify, track } = useSegment({
    apiKey: process.env.NEXT_PUBLIC_SEGMENT_WRITE_KEY || '',
  });

  return {
    identify,
    trackEvent: (eventDetails: EventProperties) => {
      track(eventDetails.name, eventDetails.data || {}, PRODUCT_NAME);
    },
  };
};
